@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 0 0% 16%;

    --card: 0 0% 100%;
    --card-foreground: 0 0% 4%;

    --popover: 0 0% 96%;
    --popover-foreground: 0 0% 4%;

    --primary: 203 93% 66%;
    --primary-foreground: 210 40% 98%;

    --secondary: 200 100% 97%;
    --secondary-foreground: 0 0% 9%;

    --muted: 210 40% 96%;
    --muted-foreground: 200 6% 50%;

    --accent: 0 0% 88%;
    --accent-foreground: 0 0% 9%;

    --destructive: 0 84% 60%;
    --destructive-foreground: 0 0% 98%;

    --border: 0 0% 90%;
    --input: 0 0% 90%;
    --ring: 0 0% 4%;

    --radius: 0.75rem;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}
